import React, { ComponentProps } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import ProductCard from './ProductCard';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Section } from '../common';

// type Query = {
//     prices: {
//         edges: {
//             node: {
//                 id: string;
//                 active: boolean;
//                 currency: string;
//                 unit_amount: number;
//                 product: {
//                     id: string;
//                     name: string;
//                     description?: string;
//                 };
//             };
//         }[];
//     };
// };

// const ProductsContainer = styled.div`
//     ${tw`flex mx-auto md:justify-evenly items-start justify-center flex-wrap`}
// `;

// const ProductSection = styled(Section)`
//     ${tw`sm:px-0`}
//     h2 {
//         ${tw`text-center text-5xl mb-4 leading-none`}
//     }
//     h3 {
//         ${tw`text-center text-4xl mb-10 sm:mb-20 leading-none`}
//     }
// `;

// const Products = () => {
//     return (
//         <>
//             <StaticQuery
//                 query={graphql`
//                     {
//                         prices: allStripePrice(
//                             filter: { active: { eq: true }, product: { active: { eq: true } } }
//                             sort: { fields: unit_amount }
//                         ) {
//                             edges {
//                                 node {
//                                     active
//                                     id
//                                     currency
//                                     unit_amount
//                                     product {
//                                         id
//                                         name
//                                         description
//                                         active
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 `}
//                 render={({ prices }: Query) => {
//                     // Group prices by product
//                     const products = prices.edges.reduce(
//                         (acc: ComponentProps<typeof ProductCard>['product'][], curr) => {
//                             // early return if find fails
//                             acc.push({
//                                 name: curr.node.product.name,
//                                 productId: curr.node.product.id,
//                                 description: curr.node.product.description,
//                                 id: curr.node.id,
//                                 currency: curr.node.currency,
//                                 unitAmount: curr.node.unit_amount,
//                                 active: curr.node.active,
//                             });

//                             return acc;
//                         },
//                         [],
//                     );

//                     return (
//                         <ProductSection id="plans">
//                             <h2>Nutrition Plans</h2>
//                             <h3>Customized and tailored to you!</h3>
//                             <ProductsContainer>
//                                 {products.map((val) => (
//                                     <ProductCard key={val.id} product={val} />
//                                 ))}
//                             </ProductsContainer>
//                         </ProductSection>
//                     );
//                 }}
//             />
//             <hr />
//         </>
//     );
// };

const Products = () => null;

export default Products;
