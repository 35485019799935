import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Section } from '../common';
import tw from 'twin.macro';
import Img from 'gatsby-image';

const AboutSection = styled(Section)``;

const AboutContainer = styled.div`
    ${tw`md:flex`}
`;

const TextContainer = styled.div`
    ${tw`text-center sm:text-left md:w-2/3 py-8  md:ml-16 sm:order-2`}

    h3 {
        ${tw`text-4xl text-center`}
    }
`;

const ImageContainer = styled.div`
    ${tw`text-center hidden md:block md:w-1/3 py-8 text-white`}

    div {
        max-width: 500px;
    }
`;

const About = () => {
    const {
        image: {
            sharp: { fluid },
        },
    } = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "jinda.jpg" }) {
                sharp: childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <AboutSection id="about">
            <AboutContainer as="div">
                <TextContainer>
                    <h3>About BKB</h3>
                    <p>
                        Jatinder Bains, founder and CEO of BKB Nutrition is an ISSA certified sports nutritionist whom
                        has worked alongside other renown professionals in bodybuilding and combat based sports. A
                        Brazilian Jiu-Jitsu black belt with over 15 years of experience in training and coaching, he now
                        looks to help individuals make transformative changes to their lives outside of the mats. BKB
                        offers personalized diet plans via a subscription model. Each plan comes custom tailored to each
                        individual and factors in any of their respective dietary restrictions. Gluten free? No problem.
                        Also a vegan? No problem. Have a sweet tooth?{' '}
                        <span style={{ fontWeight: 'bold' }}>No problem.</span>
                    </p>
                    <p>
                        With your subscription, you'll receive an intake form that helps that folks at BKB figure out
                        your nutrition goals and then after factoring in your goals + your restrictions, you will
                        receive a sample meal plan, a macronutrient breakdown, and 4 check-ins with BKB so that if any
                        tweaks are needed to be made to your diet based on how you are progressing, those tweaks can be
                        made.
                        <span style={{ fontWeight: 'bold' }}> We're here to help you reach your fitness goals.</span>
                    </p>
                </TextContainer>
                <ImageContainer>
                    <Img fluid={fluid} />
                </ImageContainer>
            </AboutContainer>
        </AboutSection>
    );
};

export default About;
