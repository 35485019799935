import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Products from '../components/Products';
import Contact from '../components/Contact';
import About from '../components/About';

const IndexPage = () => (
    <>
        <Layout showHero>
            <SEO title="Home" />
            <Products />
            <About />
            <Contact />
        </Layout>
    </>
);

export default IndexPage;
