import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import tw from 'twin.macro';
import { Section } from '../common';
import igLogo from '../../images/instagram-round-line.svg';

const ContactSection = styled.section`
    ${tw`bg-black`}
`;

const ContactContainer = styled(Section)`
    ${tw`sm:flex`}
`;

const ContactInfo = styled.div`
    ${tw`mt-6 text-lg`}
`;

const Left = styled.div`
    ${tw`text-center sm:text-left sm:w-1/2 py-8 text-white`}

    h3 {
        ${tw`text-4xl`}
    }
`;

const Right = styled.div`
    ${tw`text-center sm:w-1/2 py-8 text-white sm:ml-8`}

    div {
        max-width: 600px;
    }
`;

const SocialContainer = styled.div`
    ${tw`mt-16`}

    h4 {
        ${tw`mb-6`}
    }
`;

const SocialLink = styled.a`
    ${tw`inline-block`}
`;

const SocialImage = styled.img`
    width: 40px;
`;

const Contact = () => {
    const {
        image: {
            sharp: { fluid },
        },
    } = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "phone.jpg" }) {
                sharp: childImageSharp {
                    fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <ContactSection id="contact">
            <ContactContainer as="div">
                <Left>
                    <h3>Contact Info</h3>
                    <ContactInfo>
                        <a target="blank" href="mailto:bkbnutrition@outlook.com">
                            bkbnutrition@outlook.com
                        </a>
                        <SocialContainer>
                            <h4>Follow us</h4>
                            <SocialLink target="blank" href="https://www.instagram.com/bkb_nutrition/">
                                <SocialImage src={igLogo} alt="@bkb_nutrition" />
                            </SocialLink>
                        </SocialContainer>
                    </ContactInfo>
                </Left>
                <Right>
                    <Img fluid={fluid} />
                </Right>
            </ContactContainer>
        </ContactSection>
    );
};

export default Contact;
